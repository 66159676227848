<template>
  <div>
    <vc-calendar
      class="custom-calendar"
      :class="{ 'hide-arrows': type == 'year' }"
      disable-page-swipe
      :columns="type == 'year' ? 2 : 1"
      :rows="type == 'year' ? 6 : 1"
      :attributes="filteredAttributes"
      :min-date="minDate"
      :max-date="maxDate"
      locale="pt-PT"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden hover:bg-gray-200 relative" @click="setDay(day)">
          <div class="day-label text-sm text-gray-500 font-semibold">
            {{ day.day }}
          </div>
          <div class="flex-grow overflow-y-auto overflow-x-auto p-0.5">
            <p
              v-for="attr in attributes"
              :key="attr.key"
              class="text-xs leading-tight p-0.5 flex items-center justify-center font-semibold w-full h-full hover:opacity-75"
              :class="[attr.customData.class, attr.customData.type, { 'bg-opacity-50': selected == day.id }]"
              :title="attr.customData.details ?? attr.customData.title"
            >
              <v-clamp autoresize :max-lines="partialDayTypes.includes(attr.customData.type) ? 1 : 2">
                {{ attr.customData.title }}</v-clamp
              >
            </p>
          </div>
        </div>
      </template>
    </vc-calendar>
  </div>
</template>

<script>
import { FULL_DAY_TYPES, PARTIAL_DAY_TYPES } from '@/utils'

export default {
  props: {
    type: {
      type: String,
      default: 'year'
    },
    minDate: {
      type: Date
    },
    maxDate: {
      type: Date
    },
    attributes: {
      type: Array,
      default: () => []
    },
    // attributes example: [{
    //   key: 5,
    //   customData: {
    //     title: "Mia's gymnastics practice.",
    //     class: 'bg-pink-500 text-white',
    //   },
    //   dates: new Date(year, month, 11),
    // }]
    disabledDates: {
      type: Array,
      default: () => []
    },
    availableDates: {
      type: Array,
      default: () => []
    },
    selected: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fullDayTypes: FULL_DAY_TYPES,
      partialDayTypes: PARTIAL_DAY_TYPES,
      activeFilter: 'all'
    }
  },
  computed: {
    // layout() {
    //   if (this.type == 'year') {
    //     return this.$screens({
    //       // Default layout for mobile
    //       default: {
    //         columns: 1,
    //         rows: 1,
    //       },
    //       lg: {
    //         columns: 2,
    //         rows: 6,
    //       },
    //     })
    //   }
    //   return {
    //     columns: 1,
    //     rows: 1,
    //   }
    // },

    filteredAttributes() {
      console.log('activeFilter', this.activeFilter)
      let result = this.attributes

      if (this.activeFilter == 'changes')
        result = this.attributes.filter(
          el => el.customData?.type == 'holiday' || el.customData?.entry?.is_from_previous_map == false
        )
      else if (this.activeFilter == 'other')
        result = this.attributes.filter(
          el =>
            el.customData?.type == 'holiday' ||
            (el.customData?.entry?.is_from_previous_map == true && el?.entry?.sync_operation != 'del')
        )

      return result
    }
  },

  methods: {
    setDay(day) {
      console.log('clicked day', day)
      console.log('this.selected', this.selected)

      if (this.selected == day.id) {
        this.$emit('clicked-day', null)
      } else {
        this.$emit('clicked-day', day)
      }
    }
  }
}
</script>

<style lang="postcss">
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  border: 0;
  border-radius: 0;
  width: 100%;
  overflow-x: auto;
  background-color: transparent;

  &.hide-arrows .vc-arrows-container {
    display: none;
  }

  & .vc-pane-layout {
    gap: 0.5rem;
  }
  & .vc-pane {
    @apply border border-gray-200 min-w-full  shadow-sm;
    min-width: 390px;
  }

  & .vc-header {
    padding: 10px 0;
    & .vc-title {
      @apply capitalize font-semibold text-gray-500 text-lg;
    }
  }
  & .vc-weeks {
    /* grid-template-columns: repeat(5, 1fr) 0.5fr 0.5fr; // Shows weekends half size */
    @apply p-0;
  }
  & .vc-weekday {
    @apply border-b border-t border-gray-200 py-1.5;
  }
  & .vc-day {
    @apply px-0.5 py-1 text-left;
    height: 60px;
    &.weekday-1,
    &.weekday-7 {
      @apply bg-gray-300 bg-opacity-20;
    }
    &:not(.on-bottom) {
      @apply border-b border-gray-200;
    }
    &:not(.on-right) {
      @apply border-r border-gray-200;
    }

    & .morning,
    & .halfday,
    & .creditday-morning,
    & .creditday-halfday {
      word-spacing: 100vw;
      overflow: hidden;
      position: relative;
      clip-path: polygon(0% 0%, 0% 100%, 100% 0%); /* top left */
    }

    & .afternoon,
    & .creditday-afternoon {
      word-spacing: 100vw;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      clip-path: polygon(100% 0%, 0% 100%, 100% 100%); /* down right */
    }
  }

  & .vc-day-dots {
    margin-bottom: 5px;
  }
}

.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}
</style>
