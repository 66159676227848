<template>
  <div
    v-if="day"
    class="rounded-lg px-2.5 py-1.5 flex flex-col"
    :class="{
      'bg-primary bg-opacity-5': asChangelog && day.sync_operation === 'add',
      'bg-yellow-500 bg-opacity-5': asChangelog && day.sync_operation === 'acc' && !simpleView,
      'bg-red-500 bg-opacity-5': asChangelog && day.sync_operation === 'del' && !simpleView,
      'bg-gray-100 bg-opacity-50': !asChangelog || (day.sync_operation !== 'add' && simpleView)
    }"
  >
    <div class="flex gap-2 items-center min-h-9">
      <div class="flex-1 flex items-center gap-5">
        <div v-if="simpleView" class="w-32 flex gap-1 items-center font-semibold">
          <fw-icon-calendar class="h-5 w-5 flex-shrink-0" />
          <span>{{ day.date | formatDate }}</span>
        </div>
        <div
          v-else
          class="w-28 flex gap-1 items-center font-semibold"
          :class="{
            'text-primary': asChangelog && day.sync_operation == 'add' && type !== 'canceled',
            'text-yellow-600': asChangelog && day.sync_operation == 'acc' && type !== 'canceled',
            'text-red-600': asChangelog && day.sync_operation == 'del' && type !== 'canceled',
            'text-gray-500': asChangelog && type === 'canceled'
          }"
        >
          <fw-icon-add-circle v-if="asChangelog && day.sync_operation == 'add'" class="h-5 w-5 flex-shrink-0" />
          <fw-icon-subtract-hexagon
            v-else-if="asChangelog && day.sync_operation == 'acc'"
            class="h-5 w-5 flex-shrink-0"
          />
          <fw-icon-subtract-circle
            v-else-if="asChangelog && day.sync_operation == 'del'"
            class="h-5 w-5 flex-shrink-0"
          />
          <fw-icon-calendar v-else class="h-5 w-5 flex-shrink-0" />
          <span>{{ day.date | formatDate }}</span>
        </div>

        <div class="flex-1 flex gap-2 items-center text-sm">
          <div v-if="!editMode">
            <fw-tag type="xlight">{{ $t(`types.${day.type}`) }}</fw-tag>
          </div>
          <div v-else>
            <b-dropdown
              v-if="canAddPartialDay || !fullDayTypes.includes(day.type)"
              aria-role="list"
              position="is-bottom-left"
              @change="updateDayType"
            >
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="transparent"
                :label="$t(`types.${day.type}`)"
                class="font-medium text-gray-500"
              >
              </fw-button-dropdown>
              <b-dropdown-item
                label="Todo o dia"
                aria-role="listitem"
                :value="isCreditdays ? 'creditday' : 'allday'"
                :disabled="remainingDays == 0"
              >
                Todo o dia
              </b-dropdown-item>
              <b-dropdown-item
                label="Manhã"
                aria-role="listitem"
                :value="isCreditdays ? 'creditday-morning' : 'morning'"
              >
                Manhã
              </b-dropdown-item>
              <b-dropdown-item
                label="Tarde"
                aria-role="listitem"
                :value="isCreditdays ? 'creditday-afternoon' : 'afternoon'"
              >
                Tarde
              </b-dropdown-item>
            </b-dropdown>
            <div v-else>
              <fw-tag type="xlight">{{ $t(`types.${day.type}`) }}</fw-tag>
            </div>
          </div>
          <div v-if="type == 'latest' && day.sync_operation" class="opacity-50 text-xs font-medium">
            <div v-if="day.sync_operation == 'acc'" class="flex items-center gap-0.5">
              <fw-icon-actions class="h-4 w-4 flex-shrink-0" />
              Dia cumulado
            </div>
          </div>
          <div
            v-else-if="type !== 'normal' && day.sync_operation"
            class="opacity-50 text-xs font-medium flex items-center gap-0.5"
          >
            <div>
              <fw-icon-actions class="h-4 w-4 flex-shrink-0" />
            </div>
            <div v-if="day.sync_operation == 'acc'">
              {{ isVersionCanceled ? 'Dia a cumular' : 'Dia cumulado' }}
            </div>
            <div v-if="day.sync_operation == 'add'">
              {{ isVersionCanceled ? 'Dia a adicionar' : 'Dia adicionado' }}
            </div>
            <div v-if="day.sync_operation == 'del'">
              {{ isVersionCanceled ? 'Dia a remover' : 'Dia removido' }}
            </div>
          </div>
        </div>

        <div v-if="!editMode && managerMode">
          <div v-if="day.source != 'sap'" class="text-sm">
            <div v-if="day.sync_status == 'error'" class="flex gap-1 items-center text-red-600">
              <b-tooltip label="Ocorreu um erro de sincronismo para SAP RH" type="is-light" position="is-left">
                <fw-icon-alert class="h-5 w-5 flex-shrink-0" />
              </b-tooltip>
              <div v-if="canViewSyncDetails" class="text-sm">
                {{ day.sync_return_code ? `${day.sync_return_code}: ${day.sync_message}` : day.sync_message }}
              </div>
            </div>
            <div v-else-if="day.sync_status !== 'synced'" class="flex gap-1 items-center text-yellow-600">
              <b-tooltip label="Comunicação para SAP RH pendente" type="is-light" position="is-left">
                <fw-icon-calendar-schedule class="h-5 w-5 flex-shrink-0" />
              </b-tooltip>
            </div>
          </div>

          <div v-if="day.sync_date" class="text-xs flex gap-1 items-center justify-end text-gray-500">
            <fw-icon-history class="h-4 w-4 flex-shrink-0" />
            <div>{{ day.sync_date | formatDateTime }}</div>
          </div>
        </div>

        <div v-if="editMode">
          <fw-button
            v-if="day.sync_status != 'synced' || day.sync_operation != 'del'"
            type="link-muted"
            :disabled="savingData"
            @click.native="removeChange"
          >
            {{ type === 'change' ? 'Cancelar' : 'Remover' }}
          </fw-button>
        </div>
        <div v-else-if="accumulateMode">
          <fw-button type="link-muted" :disabled="savingData" @click.native="accumulateDay">
            Cumular dia
          </fw-button>
        </div>
      </div>
    </div>
    <slot name="secondline"></slot>
  </div>
</template>

<script>
import { FULL_DAY_TYPES } from '@/utils'

export default {
  props: {
    day: {
      type: Object,
      default: () => {}
    },
    isCreditdays: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    },
    year: {
      type: Number
    },
    remainingDays: {
      type: Number,
      default: 0
    },
    canAddPartialDay: {
      type: Boolean,
      default: false
    },
    simpleView: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    accumulateMode: {
      type: Boolean,
      default: false
    },
    managerMode: {
      type: Boolean,
      default: false
    },
    asChangelog: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'normal'
    }
  },

  data() {
    return {
      dayType: 'allday',
      fullDayTypes: Object.freeze(FULL_DAY_TYPES)
    }
  },

  computed: {
    isCurrentMapYear() {
      return new Date(this.day.date).getFullYear() == this.year
    },
    isVersionCanceled() {
      return ['canceled'].includes(this.type)
    }
  },

  mounted() {
    this.dayType = this.day.type
  },

  methods: {
    userPermissions() {
      return this.$store.getters.userPermissions
    },

    updateDayType(newType) {
      this.dayType = newType
      console.log('update', this.dayType)
      this.$emit('update', {
        date: this.day.date,
        type: newType
      })
    },

    removeChange() {
      console.log('removeDay', this.day.date)
      this.$emit('delete', this.day.date)
    },

    accumulateDay() {
      console.log('accumulateDay', this.day)
      this.$emit('accumulate', this.day)
    },

    canViewSyncDetails() {
      return this.userPermissions.isManager || this.userPermissions.isAdmin
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "birthday": "Dia aniversário",
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito / Manhã",
        "creditday-afternoon": "Dia de crédito / Tarde",
        "creditday-halfday": "Dia de crédito parcial"
      }
    },
    "en": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "birthday": "Dia aniversário",
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito / Manhã",
        "creditday-afternoon": "Dia de crédito / Tarde",
        "creditday-halfday": "Dia de crédito parcial"
      }
    }
  }
</i18n>
